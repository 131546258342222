/**
 * Created by tmedrano on 04/12/2015.
 */

'user strict';
angular
    .module('annexaApp')
    .config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {

       TranslateFunction.$inject =  ['$translate', '$translatePartialLoader'];
        function TranslateFunction($translate, $translatePartialLoader) {
        }

        $stateProvider
            .state('annexa.sign', {
                absctract: true,
	            url: '/sign',
	            redirectTo: 'annexa.sign.pending',
	            views: {
	                "": {
	                    controller: 'signController',
	                    templateUrl: './views/sign/index.html'
	                }
	            },
	            data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.title',
                    authenticate: true
                },
                resolve: {
                    translatePartialLoader: TranslateFunction
                }
            })
            .state('annexa.sign.pending', {
                url: '/sign/:typeDash/:param2',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/pending.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.title',
                    authenticate: true
                },
                resolve: {
                    translatePartialLoader: TranslateFunction
                }
            })
            .state('annexa.sign.selectSignActionType', {
                url: '/selectSignActionType',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/selectSignActionType.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
            .state('annexa.sign.queue', {
                url: '/queue',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/queued.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                },
                resolve: {
                    getRegisterEntryLists: ['SignService', function (SignService) {
                        return SignService.transitionToSignActionTypes();
                    }]
                }
            })
            .state('annexa.sign.serverSign', {
                url: '/serverSign',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/queued.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
            .state('annexa.sign.cloudSignAndorra', {
                url: '/cloudSignAndorra/:typeDash/:param2',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/cloudSignAndorra.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
            .state('annexa.sign.bioSign', {
                url: '/bioSign/:typeDash/:param2',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/signBiometric.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
            .state('annexa.sign.centralizedSign', {
                url: '/centralizedSign/:typeDash/:param2',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/signCentralized.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
           .state('annexa.sign.autofirma', {
                url: '/autofirma/:typeDash/:param2',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/signAutofirma.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                },
                resolve: {
                    getRegisterEntryLists: ['SignService', function (SignService) {
                        return SignService.cargarAutofirma();
                    }]
                }
            })
           .state('annexa.sign.applet', {
                url: '/queueApplet',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/queued_applet.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.queued.title',
                    authenticate: true
                }
            })
            .state('annexa.sign.finished', {
                url: '/finished',
                views: {
                    "mainsign@annexa.sign": {
                        templateUrl: './views/sign/finished.html'
                    }
                },
                data: {
                    title: 'global.sign.toptitle',
                    displayName: 'global.sign.finished.title',
                    authenticate: true
                }
            });

    }]);


