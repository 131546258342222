/**
 * Created by osirvent on 05/06/2015.
 */
angular.module('annexaApp')
    .factory('ModalSignConflict',['$http', '$translate', '$compile', '$state', 'apiSign', 'SignService', function($http, $translate, $compile, $state, apiSign, SignService) {
        var ModalSignConflict = function($scope, module) {
            this.initModal(null,$scope);
        };

        //-- Inici funcions modals --//
        ModalSignConflict.prototype.initModal = function (dataValids, $scope){
            if(dataValids != null && dataValids != undefined){
                $scope.dataValids = dataValids;
            }else{
                if($scope.dataValids != null && $scope.dataValids != undefined) {
                    $scope.dataValids.length = 0;
                }else{
                    $scope.dataValids = [];
                }
            }
        };

        ModalSignConflict.prototype.eliminarDadesScope = function ($scope){
            this.initModal(null,$scope);
        };

        ModalSignConflict.prototype.isDisabled = function ($scope){
           for(var i =0; i< $scope.tableQueued.list.length; i++){
               if($scope.tableQueued.list[i].position == 0) {
                   return true;
               }
           }
            return false;
        };
        //-- Fi funcions modals--//
        return ModalSignConflict;
    }]);