/**
 * Created by tmedrano on 07/12/2015.
 */
angular
    .module('annexaApp')
    .service('SignService',['$q', '$http', 'apiSign', '$rootScope','$state', function ($q, $http,  apiSign, $rootScope, $state) {
        return {
            updateObject:function(url,id,content){
                var deferrend = $q.defer();
                $http({
                    method: 'PATCH',
                    url: url+"/"+id,
                    headers: {'Content-Type': 'application/json'},
                    data:content
                }).success(function(data, status) {
                    deferrend.resolve(data);
                })
                .error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateConfig:function(url,data){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: url,
                    params: {
                        config:angular.fromJson(data)
                    }
                }).success(function(data, status) {
                        deferrend.resolve(data);
                    })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            addToSign: function(id, position){
                var service=this;
                var deferrend = $q.defer();

                var content = {
                    id:id,
                    actionExecutionDate:new Date(),
                    userPosition:((position)?((position.id)?{id:position.id}:{id:position}):undefined)
                };
                $http({
                    method: 'PUT',
                    url: './api/signprocessactions/addToSign/'+id,
                    headers: {'Content-Type': 'application/json'},
                    data: content
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;

            },
            updatePosition: function(id, position){
                var service=this;
                var deferrend = $q.defer();
                
                $http({
                    method: 'PUT',
                    url: './api/signprocessactions/updatePosition/'+id+'/'+position
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;

            },
            removeFromSign: function(id){
                var service=this;
                var deferrend = $q.defer();
                var content = {
                    id:id,
                    actionExecutionDate:null
                };
                service.updateObject(apiSign.getSignProcessActions,id,content).then(function (res){
                    deferrend.resolve(res);
                }).catch(function (msg) {
                    deferrend.reject(msg);
                });

                return deferrend.promise;

            },
            validateSign: function(row, iddoc){

                var deferrend = $q.defer();
                var content = {
                    id:row.id,
                    user:$rootScope.LoggedUser.id
                };
                $http({
                    method: 'POST',
                    url: './api/signprocessactions/validate',
                    headers: {'Content-Type': 'application/json'},
                    params: content
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;

            },
            rejectSign: function(row,iddoc,rejectedComment){
			    var deferrend = $q.defer();
				if(!rejectedComment){
					deferrend.reject('No comment in reject');
				}else{
	                var content = {
	                    id:row.id,
	                    user:$rootScope.LoggedUser.id,
	                    rejectComment:rejectedComment
	                };
	                $http({
	                    method: 'POST',
	                    url: './api/signprocessactions/reject',
	                    headers: {'Content-Type': 'application/json'},
	                    params: content
	                }).success(function(data, status) {
	                    deferrend.resolve(data);
	                }).error(function(msg,code) {
	                    deferrend.reject(msg);
	                });
				}
                return deferrend.promise;
            },
            doSign: function(row,cert,pin,position,firstpage){
                var deferrend = $q.defer();
                var content={};
                content.signProcessActionId =row.id;
                content.documentId=row.signProcess.document.id;
                content.certId=cert.id;
                content.pin=pin;
                content.position=position;
                content.firstpage=firstpage;
                content.order=row.actionOrder;
                $http({
                    method: 'POST',
                    url: apiSign.executeSign,
                    headers: {'Content-Type': 'application/json'},
                    params: content
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            formatDate: function(date, add, del){
                if(add) {
                    date.setDate(date.getDate() + 1);
                }
                if(del){
                    date.setDate(date.getDate() -1);
                }
                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10){dd='0'+dd}
                if(mm<10){mm='0'+mm}
                return mm+'/'+dd+'/'+yyyy;
            },
            getUserCertificates: function (userId) {
                var deferrend = $q.defer();
                $http.get(apiSign.getUserCertificates+'?userId='+userId).success(function(data, status) {
                    deferrend.resolve(data);
                })
                .error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            transitionToSignActionTypes: function () {
            	var userId = $rootScope.LoggedUser.id;
            	$rootScope.$broadcast('selectSignActionTypeOK', {lstSignActionTypeSelected: []});
        		return $http.get(apiSign.getSignActionTypeSelected+'?userId='+userId).success(function(data, status) {
            		if (data) {
	            		//En funcion del tipo de firma se cambia al estado que corresponda            	
	                	var numSignActionTypeSelected = $linq(data).count();
	                	if (numSignActionTypeSelected == 1) {
	                		//Hay documentos para firmar con un solo un tipo de firma, se cambia al estado que corresponda el tipo de firma
	                		var code = $linq(data).first().signActionTypeCode;	                		
	                    	if (code == apiSign.SIGNATURE_RECOGNIZED_CODE) {
	                    		$state.transitionTo('annexa.sign.applet');
	                    	} else if (code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE) {
	                    		$state.transitionTo('annexa.sign.cloudSignAndorra');
	                    	} else if (code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE) {
	                    		$state.transitionTo('annexa.sign.bioSign');
	                    	} else if (code == apiSign.SIGNATURE_AUTOFIRMA_CODE) {
	                    		$state.transitionTo('annexa.sign.autofirma');
	                    	}
	                    	//agregar aqui la redireccion al estado correspondiente de otros tipos de firma
	                	} else if (numSignActionTypeSelected > 1) {
	                		//Hay mas de un tipo de firma
	                		$rootScope.$broadcast('selectSignActionTypeOK', {lstSignActionTypeSelected: angular.toJson(data)});
	                		$state.transitionTo('annexa.sign.selectSignActionType');
	                	}
            		}
                })
                .error(function(msg,code) {
                	
                });            	               
            },
            getBioSignDevices: function(url, auth){
            	var deferred = $q.defer();
            	$http.get('./api/sign/biometricSignDevices').success(function(data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    console.error(error);
                    deferred.reject(error);
                });
            	return deferred.promise;
            },
			cargarAutofirma: function(){
				var deferred = $q.defer();
            	AutoScript.cargarAppAfirma();
                deferred.resolve(true);
                return deferred.promise;
			},
            getBase64Document: function(id){
            	var deferred = $q.defer();
            	$http.get('./api/sign/signature?id='+id+'&base64=true').success(function(data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    console.error(error);
                    deferred.reject(error);
                });
            	return deferred.promise;
            },
			updateScreen: function(){
                var deferrend = $q.defer();
                deferrend.resolve("OK");
                return deferrend.promise;
            }
			
        }
    }]);